import axios from "axios";
import { Action, Thunk, thunk, action } from "easy-peasy";
import { CMS_ITEMS, CMS_PARAMS } from "utils/cmsConstants";

export interface CmsContentModel {
  airlines: any[];
  service: any[];
  serviceCategory: any[];
  catalogueSource: any[];
  clearAirlines: Action<CmsContentModel>;
  setAirlines: Action<CmsContentModel, any>;
  fetchAirline: Thunk<CmsContentModel, any>;
  clearService: Action<CmsContentModel>;
  setService: Action<CmsContentModel, any>;
  fetchService: Thunk<CmsContentModel, any>;
  clearServiceCategory: Action<CmsContentModel>;
  setServiceCategory: Action<CmsContentModel, any>;
  fetchServiceCategory: Thunk<CmsContentModel, any>;
  clearCatalogueSource: Action<CmsContentModel>;
  setCatalogueSource: Action<CmsContentModel, any>;
  fetchCatalogueSource: Thunk<CmsContentModel, any>;
  setOrgReference: Action<CmsContentModel, any>;
  orgReference: any;
  fetchPageCardUsageData: Thunk<CmsContentModel, any>;
  pageCardUsageData: any[];
  setPageCardUsageData: Action<CmsContentModel, any>;
  fetchAirports: Thunk<CmsContentModel, any>;
  setAirports: Action<CmsContentModel, any>;
  airports: any[];
  modeOfTransport: any;
  setModeOfTransport: Action<CmsContentModel, any>;
  clearAirports: Action<CmsContentModel, any>;
}

const CmsContentStore: CmsContentModel = {
  airlines: [],
  service: [],
  serviceCategory: [],
  catalogueSource: [],
  orgReference: null,
  pageCardUsageData: [],
  airports: [],
  modeOfTransport: [],

  setOrgReference: action((state, reference) => {
    state.orgReference = reference;
  }),

  setModeOfTransport: action((state, mode) => {
    state.modeOfTransport = mode;
  }),

  setCatalogueSource: action((state, catalogueSource) => {
    state.catalogueSource = catalogueSource;
  }),

  clearCatalogueSource: action((state) => {
    state.catalogueSource = [];
  }),

  setPageCardUsageData: action((state, pageCardUsageData) => {
    state.pageCardUsageData = pageCardUsageData;
  }),

  setAirports: action((state, airportList) => {
    state.airports = airportList;
  }),

  fetchCatalogueSource: thunk(async (actions) => {
    actions.clearCatalogueSource();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CMS_URL}/items/${CMS_ITEMS.CATALOGUE_SOURCE}`,
        {
          params: CMS_PARAMS.catalogue_source,
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_CMS_TOKEN}`,
          },
        }
      );
      actions.setCatalogueSource(response.data.data || []);
    } catch (e) {
      console.log(e);
    }
  }),

  setServiceCategory: action((state, serviceCategory) => {
    state.serviceCategory = serviceCategory;
  }),

  clearServiceCategory: action((state) => {
    state.serviceCategory = [];
  }),

  fetchServiceCategory: thunk(async (actions) => {
    actions.clearCatalogueSource();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CMS_URL}/items/${CMS_ITEMS.SERVICE_CATEGORY}`,
        {
          params: CMS_PARAMS.service_category,
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_CMS_TOKEN}`,
          },
        }
      );
      actions.setServiceCategory(response.data.data || []);
    } catch (e) {
      console.log(e);
    }
  }),

  setService: action((state, service) => {
    state.service = service;
  }),

  clearService: action((state) => {
    state.service = [];
  }),

  fetchService: thunk(async (actions) => {
    actions.clearService();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CMS_URL}/items/${CMS_ITEMS.SERVICE}`,
        {
          params: CMS_PARAMS.service,
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_CMS_TOKEN}`,
          },
        }
      );
      response.data.data.map(async (item: any) => {
        if (item.icon) {
          const response = await axios.get(
            `${process.env.REACT_APP_CMS_URL}/assets/${item.icon}?access_token=${process.env.REACT_APP_CMS_TOKEN}`
          );
          item.icon = response.data;
        }
        return item;
      });
      actions.setService(response.data.data || []);
    } catch (e) {
      console.log(e);
    }
  }),

  setAirlines: action((state, airline) => {
    state.airlines = airline;
  }),

  clearAirlines: action((state) => {
    state.airlines = [];
  }),

  fetchAirline: thunk(async (actions) => {
    actions.clearAirlines();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CMS_URL}/items/${CMS_ITEMS.AIRLINE}`,
        {
          params: CMS_PARAMS.airline,
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_CMS_TOKEN}`,
          },
        }
      );
      actions.setAirlines(response.data.data || []);
    } catch (e) {
      console.log(e);
    }
  }),

  fetchPageCardUsageData: thunk(async (actions, value) => {
    const params = {
      "filter[agency_short_code][_eq]": value,
      fields: `${CMS_PARAMS.page_card_usage_data.fields}${CMS_PARAMS.page_card_usage_data_fields_item.fields}`,
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CMS_URL}/items/${CMS_ITEMS.PAGE_CARD_USAGE_DATA}`,
        {
          params: params,
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_CMS_TOKEN}`,
          },
        }
      );

      const responeData = response.data?.data;
      actions.setPageCardUsageData(responeData || []);
    } catch (e) {
      console.log(e);
    }
  }),

  clearAirports: action((state, value) => {
    state.airports = [];
  }),

  fetchAirports: thunk(async (actions, value, cmsStore) => {
    actions.clearAirports(null);
    const modeOfTransport = cmsStore.getState().modeOfTransport;
    const stationParams = {
      ...CMS_PARAMS.stations,
      "filter[_and][0][type]":
        modeOfTransport && modeOfTransport === "Helicopter" ? null : "airport",
      "filter[_and][1][_or][0][location_code][_icontains]": value,
      "filter[_and][1][_or][1][station_name_en][_icontains]": value,
      "filter[_and][1][_or][2][city_name_en][_icontains]": value,
      "filter[_and][2][status][_eq]": "published",
      sort: "location_code",
      // "limit":30,
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CMS_URL}/items/${CMS_ITEMS.STATIONS}`,
        {
          params: stationParams,
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_CMS_TOKEN}`,
          },
        }
      );

      const responseData = response.data.data;
      actions.setAirports(responseData || []);
    } catch (e) {
      console.log(e);
    }
  }),
};

export default CmsContentStore;
