export const CMS_ITEMS = {
  CATALOGUE_SOURCE: "catalogue_source",
  SERVICE_CATEGORY: "service_category",
  SERVICE: "service",
  AIRLINE: "airlines",
  PAGE_CARD_USAGE_DATA: "page_card_usage_data",
  STATIONS: "stations",
};

export const CMS_PARAMS = {
  catalogue_source: {
    fields: "id,name,taxonomy_code",
    deep: "*",
  },
  service_category: {
    fields: "id,icon,name,taxonomy_code",
    deep: "*",
  },
  service: {
    fields: `id,name,taxonomy_code,description,icon,image,svg_icon,airline_content.order_quantity_uom.code`,
    deep: `{ "airline_content": { "_filter": { "airlines_id": { "short_code": { "_eq": "SQ" }}}}}`,
    limit: `-1`,
  },
  airline: {
    fields: "id, name, icon, small_icon, display_name, short_code",
  },
  page_card_usage_data: {
    fields: "status,card_brand.logo,agency_short_code,card_brand.brand_code,",
    deep: "*",
  },
  page_card_usage_data_fields_item: {
    fields:
      "fields.item.id,fields.item.name,fields.item.description,fields.item.required,fields.item.maps_to",
    deep: "*",
  },
  stations: {
    fields:
      "type,station_name_en,location_code,sub_type,city_name_en,iso_country",
    deep: "*",
  },
};
